<template>
    <div class="message-text">
        <div class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                <span>굿퍼슨 이용방법</span>
            </div>
            <div class="noti-body" v-for="item in howToUse" :key="item.id">
                <p class="spoqa-f-bold">{{ item.title }}</p>
                <div v-html="item.content" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionHowtoUse',
    computed: {
        howToUse() {
            return [
                {
                    id: 1,
                    title: '1. 프로필 전달',
                    content:
                        '오늘부터 1~2주 간격으로 오후 12시(정오)에 2~4장을 전달드릴 거에요. 정기적으로 드리는 기본 프로필이니, 놓치지 말아주세요!<br>참고로 오늘은 주선 첫 날이라 지금 바로 프로필을 드릴 거에요. 이용 방법을 다 읽고 오늘의 프로필도 확인해주세요 😃',
                },
                {
                    id: 2,
                    title: '2. 호감 표현',
                    content:
                        '프로필 확인 후 마음에 드시면 “소개받을게요”를 눌러주세요. 그래야 상대에게 호감을 표현할 수 있어요',
                },
                {
                    id: 3,
                    title: '3. 약속잡기',
                    content:
                        '서로 호감을 표현하면, 즉시 상대와 만날 약속 일정과 장소를 잡게 돼요. 약속을 잡는 과정은 저와 함께 이 채팅방에서 진행 된다는 점도 기억해주세요!',
                },
                {
                    id: 4,
                    title: '4. 만남 피드백',
                    content:
                        '두 분의 만남 다음날, 만남에 대한 피드백이 진행돼요. 더 나은 서비스 제공과 매칭율을 높이는데 사용되니 꼭 작성해주세요~',
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.open-chat {
    //padding: 9px 4px;
    padding: 0 4px;
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
}
</style>
